import { FC, ReactNode, Fragment, useState } from 'react'
import SideMenu from './SideMenu'
import TopNav from '../Common/TopNav'
import { getLS, setLS } from '@utils/global-helpers'
import { JobsDrawer } from './JobsDrawer/JobsDrawer'
import { useApiJobs } from '@hooks/useApiJobs'
import { BannerMsgStrip } from './BannerMsgStrip/BannerMsgStrip'
import { useAppInit } from '@hooks/useAppInit'
import SnackBarElement from '@components/Common/SnackBarElement'

interface ILayoutProps {
  children: ReactNode
}

export const AppLayout: FC<ILayoutProps> = ({ children }) => {
  const { data: appInitQuery } = useAppInit()
  const { jobs } = useApiJobs()

  const [showBanner, setShowBanner] = useState<boolean>(() => {
    if (!appInitQuery?.banner_msg) return

    const lastAcknowledgedMessage = getLS('lastAcknowledgedMessage')
    return appInitQuery.banner_msg !== lastAcknowledgedMessage
  })

  return (
    <Fragment>
      <SideMenu />

      <div className='app-layout'>
        <BannerMsgStrip
          showBanner={showBanner}
          onClick={() => {
            setShowBanner(false)
            setLS('lastAcknowledgedMessage', appInitQuery?.banner_msg)
          }}
          message={appInitQuery?.banner_msg}
        />

        <TopNav />

        {children}

        <SnackBarElement />
      </div>

      {jobs?.length > 0 && <JobsDrawer jobs={jobs} />}
    </Fragment>
  )
}
