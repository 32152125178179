import { App } from './pages/App/App'
import { I18nextProvider } from 'react-i18next'
import { createRoot } from 'react-dom/client'
import i18n from './i18n/i18n'
import { BrowserRouter } from 'react-router-dom'
import { QueryClient } from '@libs/useQuery'
import { QueryClientProvider } from '@tanstack/react-query'
import '@pages/Settings/Settings.scss' // For add org and settings page
import '@components/Common/ErrorBoundaryComp/ErrorBoundaryComp.scss'
import 'highlight.js/styles/atom-one-dark.css'
import './styles/globals/index.scss'
import { browserTracingIntegration, replayIntegration, init as SentryInit } from '@sentry/react'

class DebuggedBrowserRouter extends BrowserRouter {
  constructor(props) {
    super(props)

    if (import.meta.env.MODE === 'production') return

    // @ts-ignore
    console.log(`Initial path is: ${this.history.location.pathname}`)

    // @ts-ignore
    this.history.listen((location, action) => {
      // @ts-ignore
      console.log(`History changed => Path: "${location.pathname}"; Action: "${action?.toLowerCase()}"`)
    })
  }
}

SentryInit({
  enabled: import.meta.env.MODE === 'production',
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: import.meta.env.MODE,
  integrations: [browserTracingIntegration(), replayIntegration()],

  tracesSampleRate: 1.0, //  Capture 100% of the transactions

  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],

  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

const root = createRoot(document.getElementById('root'))

root.render(
  <I18nextProvider i18n={i18n}>
    <QueryClientProvider client={QueryClient}>
      <DebuggedBrowserRouter>
        <App />
      </DebuggedBrowserRouter>
    </QueryClientProvider>
  </I18nextProvider>,
)
