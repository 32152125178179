export const ORGS = (orgId?: number) => (orgId ? `v1/orgs/${orgId}` : `v1/orgs`)

export const BASE_ROUTES = {
  INTEGRATIONS: (orgId?: number) => `${ORGS(orgId)}/integrations`,
  USERS: (orgId?: number) => (orgId ? `v1/users/${orgId}` : `v1/users`),
  DNS: (orgId: number) => `${ORGS(orgId)}/dns`,
  ISSUES: (orgId: number) => `${ORGS(orgId)}/issues`,
  NETBLOCKS: (orgId: number) => `${ORGS(orgId)}/assets/ips/netblocks`,
  ASSETS: (orgId: number) => `${ORGS(orgId)}/assets`,
  VULNS: (orgId: number) => `${ORGS(orgId)}/vulns`,
  TECHS: (orgId: number) => `${ORGS(orgId)}/technologies`,
  SSO: (orgId: number) => `${ORGS(orgId)}/sso`,
  AUTH: (orgId?: number) => (orgId ? `v1/auth/${orgId}` : `v1/auth`),
  MFA: (orgId?: number) => (orgId ? `v1/auth/${orgId}` : `v1/auth`),
  ASSET_CONNECTIONS: (orgId: number) => `${ASSETS(orgId)}/connections`,
} as const

const { INTEGRATIONS, USERS, DNS, ISSUES, ASSETS, VULNS, NETBLOCKS, AUTH, TECHS, ASSET_CONNECTIONS } =
  BASE_ROUTES

// Fast API Routes

export const USERS_ROUTES = {
  DEACTIVATE: (orgId: number, userId: number) => `${USERS(orgId)}/${userId}/disable`,
  ACTIVATE: (orgId: number, userId: number) => `${USERS(orgId)}/${userId}/enable`,
  UPDATE: (orgId: number, userId: number) => `${USERS(orgId)}/${userId}`,
  UPDATE_ROLE: (orgId: number, userId: number) => `${USERS(orgId)}/${userId}/role`,
  RESET_PWD: (orgId: number, userId: number) => `${USERS(orgId)}/${userId}/reset-password`,
  UNENROLL_MFA: (orgId: number, userId: number) => `${USERS(orgId)}/${userId}/mfa/unenroll`,
} as const

export const INTEGRATIONS_ROUTES = {
  BASE: (orgId: number, integrationId?: number) =>
    integrationId ? `${INTEGRATIONS(orgId)}/${integrationId}` : INTEGRATIONS(orgId),
} as const

export const INIT_ROUTES = {
  BASE: `v1/init`,
} as const

export const AUTH_ROUTES = {
  LOGIN: `${AUTH()}/login`,
  PRELOGIN: `${AUTH()}/prelogin`,
  LOGOUT: `${AUTH()}/logout`,
  REGISTER: `${AUTH()}/register`,
  RESET_PWD: `${AUTH()}/reset-password`,

  CHECK_INVITATION_TOKEN: `${AUTH()}/check-invitation`,
  CHECK_RESET_PWD_TOKEN: `${AUTH()}/check-reset-pwd`,
  CHECK_SESSION: `${AUTH()}/check-session`,
  INVITE: (orgId: number) => `${AUTH(orgId)}/invite`,
  REINVITE: (orgId: number, userId: number) => `${AUTH(orgId)}/invite/${userId}`,
  REQ_PWD_RESET: (orgId: number, userId: number) => `${AUTH(orgId)}/request-password-reset/${userId}`,
  REQ_MFA_RESET: (orgId: number, userId: number) => `${AUTH(orgId)}/request-mfa-reset/${userId}`,

  // MFA
  MFA_DATA: `${AUTH()}/mfa`,
  ENABLE_MFA: `${AUTH()}/mfa/enable`,
  ENABLE_MFA_AUTHENTICATED: `${AUTH()}/mfa/enable-authenticated`,
  VERIFY_MFA: `${AUTH()}/mfa/verify`,
} as const

export const CREDS_ROUTES = {
  BASE: (orgId: number) => `${ORGS(orgId)}/credentials`,
  DETAILS: (orgId: number, credentialId: number) => `${ORGS(orgId)}/credentials/${credentialId}`,
  FILTER_VALUES: (orgId: number) => `${ORGS(orgId)}/credentials/filters`,
  CSV: (orgId: number, credId: number) => `${ORGS(orgId)}/credentials/${credId}/csv`,
}

export const DNS_ROUTES = {
  GET_DNS_FILTER_VALUES: (orgId: number) => `${DNS(orgId)}/filters`,
  CSV: (orgId: number, dnsId: number) => `${DNS(orgId)}/${dnsId}/csv`,
} as const

export const VULNS_ROUTES = {
  HEADER: (orgId: number) => `${VULNS(orgId)}/header`,
  CVES: (orgId: number) => `${VULNS(orgId)}/cves`,
  FILTERS: (orgId: number) => `${VULNS(orgId)}/cves/filters`,
  CVES_ID: (orgId: number, cveId: string) => `${VULNS(orgId)}/cves/${cveId}`,
  UPDATE_VULN: (orgId: number, vulnId: number) => `${VULNS(orgId)}/${vulnId}`,
  HOSTS: (orgId: number, cveId: string) => `${VULNS(orgId)}/${cveId}`,
  CSV: (orgId: number, cveId: string) => `${VULNS(orgId)}/${cveId}/csv`,
} as const

export const NETBLOCKS_ROUTES = {
  UPDATE: (orgId: number, netblockId: number) => `${ORGS(orgId)}/assets/ips/netblocks/${netblockId}`,
  ADD_IPS: (orgId: number) => `${ASSETS(orgId)}/ips/create`,
  CSV: (orgId: number, netblockId: number) => `${NETBLOCKS(orgId)}/${netblockId}/csv`,
} as const

export const ORGANIZATION_ROUTES = {
  API: (orgId: number) => `${ORGS(orgId)}/api_keys`,
  API_GENERATE: (orgId: number) => `${ORGS(orgId)}/api_keys/generate`,
  ORGANIZATIONS_SCREEN: (orgId: number) => `${ORGS(orgId)}`,
  ORGANIZATIONS_HEADER: (orgId: number) => `${ORGS(orgId)}/header`,
  REACTIVATE_ORG: (orgId: number) => `${ORGS(orgId)}/activate`,
  DEACTIVATE_ORG: (orgId: number) => `${ORGS(orgId)}/deactivate`,
  UPDATE_ORG: (orgId: number) => `${ORGS(orgId)}`,
  UPDATE_ORG_SETTINGS: (orgId: number) => `${ORGS(orgId)}/settings`,
  CONFIG_OPTIONS: `v1/config_options`,
} as const

export const API_JOBS_ROUTES = {
  BASE: (orgId: number) => `${ORGS(orgId)}/api-jobs`,
} as const

export const ISSUES_ROUTES = {
  SEVERITY: (orgId: number) => `${ISSUES(orgId)}/severity`,
  STATUS: (orgId: number) => `${ISSUES(orgId)}/status`,
  FILTER_VALUE: (orgId: number) => `${ISSUES(orgId)}/filters`,
  ISSUES_UPDATE_TAGS: (orgId: number) => `${ISSUES(orgId)}/tags`,
  DETAILS: (issueId: number, orgId: number) => `${ISSUES(orgId)}/${issueId}`,
  GROUPS: (orgId: number) => `${ISSUES(orgId)}/groups`,
  HISTORY: (issueId: number, orgId: number) => `${ISSUES(orgId)}/${issueId}/history`,
  EVIDENCE: (issueId: number, orgId: number) => `${ISSUES(orgId)}/${issueId}/evidence`,
  CUSTOM_VECTOR: (orgId: number, issueDetailId: number) => `${ISSUES(orgId)}/custom-vector/${issueDetailId}`,
  RESTORE_VECTOR: (orgId: number, issueId: number) => `${ISSUES(orgId)}/severity/${issueId}/restore`,

  RETEST_ID: (orgId: number, issueId: number) => `${ISSUES(orgId)}/${issueId}/retest`,
  RETEST: (orgId: number) => `${ISSUES(orgId)}/retest`,

  CSV: (orgId: number, issueId: number) => `${ISSUES(orgId)}/${issueId}/csv`,
} as const

export const COMMENTS_ROUTES = {
  ISSUES: (orgId: number, issueId?: number) =>
    issueId ? `${ISSUES(orgId)}/comments/${issueId}` : `${ISSUES(orgId)}/comments`,

  ASSETS: (orgId: number, assetId?: number) =>
    assetId ? `${ASSETS(orgId)}/comments/${assetId}` : `${ASSETS(orgId)}/comments`,
} as const

export const ASSETS_ROUTES = {
  ASSET_COUNT_BY_TYPE: (orgId: number, assetType: string) => `${ASSETS(orgId)}/${assetType}/count`,
  ASSETS_UPDATE_TAGS: (orgId: number) => `${ASSETS(orgId)}/tags`,
  ADD_ASSETS: (orgId: number) => `${ASSETS(orgId)}/create`,
  ASSETS_BY_TYPE: (orgId: number, assetType: string) => `${ASSETS(orgId)}/${assetType}`,

  FILTER_VALUES: (orgId: number) => `${ASSETS(orgId)}/filters/initial`,

  ISSUE_PARAMS: (orgId: number) => `${ASSETS(orgId)}/issue-params`,
  DISCOVERY_GRAPH: (orgId: number) => `${ASSET_CONNECTIONS(orgId)}/graph`,
  PORTS: (orgId: number) => `${ASSETS(orgId)}/ports`,
  HTTP: (orgId: number) => `${ASSETS(orgId)}/http`,
  HISTORY: (orgId: number) => `${ASSETS(orgId)}/history`,

  ASSETS_DETAILS: (data: { orgId: number; assetType: string; assetId: number }) => {
    const { orgId, assetType, assetId } = data
    return `${ASSETS(orgId)}/${assetType}/${assetId}`
  },

  CSV: (data: { orgId: number; assetType: string; assetId: number }) => {
    const { orgId, assetType, assetId } = data
    return `${ASSETS(orgId)}/${assetType}/${assetId}/csv`
  },
  CLOUDS_FILTERS: (orgId: number) => `${ASSETS(orgId)}/cloud/filters`,
} as const

export const DASHBOARD = {
  GET_DASHBOARD: (orgId: number) => `${ORGS(orgId)}/dashboard`,
} as const

export const TECH_ROUTES = {
  FILTER_VALUES: (orgId: number) => `${TECHS(orgId)}/filters`,
  LIST: (orgId: number) => `${TECHS(orgId)}/list`,
  GROUP: (orgId: number) => `${TECHS(orgId)}/groups`,
  HEADER: (orgId: number) => `${TECHS(orgId)}/header`,
} as const

export const SSO_ROUTES = {
  DOMAIN: (orgId: number) => `${BASE_ROUTES.SSO(orgId)}/domain`,
  DOMAIN_ID: (orgId: number, domainId: number) => `${BASE_ROUTES.SSO(orgId)}/domain/${domainId}`,
  SETTINGS: (orgId: number) => `${BASE_ROUTES.SSO(orgId)}/settings`,
  SETTINGS_ID: (orgId: number, settingsId: number) => `${BASE_ROUTES.SSO(orgId)}/settings/${settingsId}`,
} as const
