import { returnData } from '@services/http-helpers'
import { axiosInstance } from '@services/axios'
import { API_JOBS_ROUTES } from '../consts'
import { ApiJob, ApiJobType } from '@typings/api-job'
import { MessageResponse } from '@typings/generics'

class ApiJobsServiceClass {
  createJob = async (data: {
    orgId: number
    payload: { jobType: ApiJobType; jobParams?: Record<string, any> }
  }): Promise<MessageResponse & { id: string }> => {
    const { orgId, payload } = data
    return axiosInstance
      .post(API_JOBS_ROUTES.BASE(orgId), {
        type: payload.jobType,
        params: payload.jobParams,
      })
      .then(returnData)
  }

  getJobs = async (data: {
    orgId: number
    jobIds: string
    signal: AbortSignal
  }): Promise<{ jobs: ApiJob[] }> => {
    const { orgId, jobIds, signal } = data
    return axiosInstance
      .get(API_JOBS_ROUTES.BASE(orgId), { params: { job_ids: jobIds }, signal })
      .then(returnData)
  }
}

export const ApiJobsService = new ApiJobsServiceClass()
