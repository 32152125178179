import { FC, useState } from 'react'
import clsx from 'clsx'
import TopNavReport from './TopNavReport'
import TopNavOrgs from './TopNavOrgs'
import { useLocation } from 'react-router-dom'
import { ROUTES } from '@enums/routes'
import TimeFrameSelect from './TimeFrameSelect'
import UserCircle from '../UserCircle'
import UserPopover from './UserPopover'
import { useUserStore } from '@zustand/useUserStore'
import { useAppInit } from '@hooks/useAppInit'

type TopNavButtonProps = {
  children: React.ReactNode
  isActive?: boolean
} & React.ButtonHTMLAttributes<HTMLButtonElement>

export const TopNavButton: FC<TopNavButtonProps> = ({ children, isActive, ...props }) => {
  return (
    <button {...props} className={clsx('top-nav__button', props.className, { active: isActive })}>
      {children}
    </button>
  )
}

const routesWithTopNav = [ROUTES.ASSETS, ROUTES.ORGANIZATIONS, ROUTES.TECHNOLOGIES, ROUTES.PATCHING]

export const TopNav: FC = () => {
  const [userAnchorEl, setAnchorEl] = useState<HTMLElement>(null)

  const { pathname } = useLocation()
  const pathnameRoute = `/${pathname.split('/')[1]}`

  const fullName = useUserStore((s) => s.fullName)
  const { data: appInitData } = useAppInit()

  return (
    <nav className={clsx('top-nav', { radius: !routesWithTopNav.includes(pathnameRoute as ROUTES) })}>
      <TopNavOrgs appInitData={appInitData} />

      <div className='top-nav__right'>
        {pathnameRoute === ROUTES.DASHBOARD && <TimeFrameSelect />}

        <TopNavReport />

        <div className='divider' />

        <TopNavButton onClick={(e) => setAnchorEl(e.currentTarget)}>
          <UserCircle fullName={fullName} color='var(--tertiary-3)' />
        </TopNavButton>

        <UserPopover
          anchorEl={userAnchorEl}
          onClose={() => setAnchorEl(null)}
          fullName={fullName}
          appInitData={appInitData}
        />
      </div>
    </nav>
  )
}
