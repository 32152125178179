import { CSSProperties, FC } from 'react'
import InputAdornment, { InputAdornmentProps } from '@mui/material/InputAdornment'
import { Input, InputProps } from '@mui/material'
import SearchIcon from '@assets/Common/SearchIcon.svg?react'
import { UseFormRegisterReturn } from 'react-hook-form'
import clsx from 'clsx'

export type CSSVariablesProps = {
  borderColor?: string
  borderRadius?: string
  focusedBorderColor?: string
  padding?: string
  background?: string
  color?: string
}

export const DEFAULT_INPUT_STYLES: Record<keyof CSSVariablesProps, string> = {
  borderColor: 'var(--grey-10)',
  focusedBorderColor: 'var(--black-1)',
  borderRadius: 'var(--br-md)',
  padding: '6px var(--s-xxs)',
  background: 'var(--grey-12)',
  color: 'var(--blue-4)',
}

type IStyledTextFieldProps = {
  register?: UseFormRegisterReturn
  withStartSearchIcon?: boolean
  adornmentProps?: InputAdornmentProps
  cssVariablesProps?: CSSVariablesProps
  noBorder?: boolean
  fadeDisabled?: boolean
} & InputProps

const StyledTextField: FC<IStyledTextFieldProps> = ({
  withStartSearchIcon,
  adornmentProps,
  register,
  noBorder,
  cssVariablesProps = {},
  fadeDisabled,
  ...inputProps
}) => {
  return (
    <Input
      disableUnderline
      spellCheck='false'
      autoCorrect='off'
      classes={{
        root: clsx('styled-input__root', { 'no-border': noBorder, 'fade-disabled': fadeDisabled }),
        disabled: 'styled-input__disabled',
        focused: 'styled-input__focused',
        input: 'styled-input__input',
      }}
      className='styled-input'
      startAdornment={
        (withStartSearchIcon || inputProps?.startAdornment) && (
          <InputAdornment
            className='styled-input__adornment'
            position='start'
            {...adornmentProps}
            style={
              { '--adornment-clr': cssVariablesProps.color ?? DEFAULT_INPUT_STYLES.color } as CSSProperties
            }
          >
            {withStartSearchIcon ? <SearchIcon /> : inputProps?.startAdornment}
          </InputAdornment>
        )
      }
      {...inputProps}
      {...register}
      style={
        {
          '--border-color': cssVariablesProps.borderColor ?? DEFAULT_INPUT_STYLES.borderColor,
          '--border-color-focused':
            cssVariablesProps.focusedBorderColor ?? DEFAULT_INPUT_STYLES.focusedBorderColor,
          '--border-radius': cssVariablesProps.borderRadius ?? DEFAULT_INPUT_STYLES.borderRadius,
          '--padding': cssVariablesProps.padding ?? DEFAULT_INPUT_STYLES.padding,
          '--background': cssVariablesProps.background ?? DEFAULT_INPUT_STYLES.background,
          '--color': cssVariablesProps.color ?? DEFAULT_INPUT_STYLES.color,
          ...inputProps.style,
        } as CSSProperties
      }
    />
  )
}

export default StyledTextField
