export const QUERY_KEYS = {
  // Auth
  checkInvitationToken: 'checkInvitationToken',
  checkResetPwdToken: 'checkResetPwdToken',
  getEnableMFAData: 'getEnableMFAData',

  // Globals
  configOptions: 'configOptions',
  appInit: 'appInit',
  apiJobs: 'apiJobs',
  users: 'users',

  // Settings
  apiKey: 'apiKey',
  ssoData: 'ssoData',
  integrations: 'integrations',

  // Tables
  issuesTable: 'issuesTable',
  issueGroups: 'issueGroups',

  assetsTable: 'assetsTable',

  dnsTable: 'dnsTable',

  credentialsTable: 'credentialsTable',

  netblocks: 'netblocks',

  techsHeader: 'techsHeader',
  techsGroupTable: 'techsGroupTable',
  techsHostsTable: 'techsHostsTable',

  cvesHeader: 'cvesHeader',
  cvesTable: 'cvesTable',

  vulnDetails: 'vulnDetails',
  vulnsTable: 'vulnsTable',

  orgsHeader: 'orgsHeader',
  orgsTable: 'orgsTable',

  // Issue details
  issueRetestData: 'issueRetestData',
  tableCounters: 'tableCounters',
  issueEvidence: 'issueEvidence',
  issueHistory: 'issueHistory',

  // Asset details
  assetPanelIssues: 'assetPanelIssues',
  assetPanelVulns: 'assetPanelVulns',
  assetPanelDns: 'assetPanelDns',
  assetPanelHttp: 'assetPanelHttp',
  assetPanelTechs: 'assetPanelTechs',
  assetConnections: 'assetConnections',
  assetHistory: 'assetHistory',

  // Shared
  assetDiscoveryGraph: 'assetDiscoveryGraph',
  filterValues: 'filterValues',
  itemComments: 'itemComments',

  dashboard: 'dashboard',
} as const

export type QueryKeys = (typeof QUERY_KEYS)[keyof typeof QUERY_KEYS]
