import { shallow } from 'zustand/shallow'
import { boolStrToBool, isObjEmpty } from '@utils/global-helpers'
import { getLS, isNill } from '@utils/global-helpers'
import { Organization } from '@typings/organization'
import { createWithEqualityFn } from 'zustand/traditional'
import computed from 'zustand-computed'
import { ELEMENT_SECURITY_ORG_ID, ORG_SETTING, TOrgSetting } from '@enums/organizations'

type OrgStoreState = {
  organization: Organization
  setOrganization: (org: Organization) => void
  getOrgSetting: (key: TOrgSetting, org: Organization) => any
  reset: () => void
}

type ComputedStore = {
  showReviewTab: boolean
  allowCredsBreachRoute: boolean
}

const { review_enabled, enable_creds_breach } = ORG_SETTING

const computeState = (s: OrgStoreState): ComputedStore => {
  const org = s.organization

  const showReviewTab = () => {
    const lsEnabled = getLS(review_enabled)
    if (!isNill(lsEnabled)) return !!lsEnabled

    const orgReviewVal = boolStrToBool(s.getOrgSetting(review_enabled, org))

    if (orgReviewVal) return orgReviewVal
  }

  const allowDynamicRoutes = org?.id === ELEMENT_SECURITY_ORG_ID // Always allow Element Security to see and enter CredsBreach routes

  return {
    showReviewTab: showReviewTab(),
    allowCredsBreachRoute: allowDynamicRoutes || boolStrToBool(s.getOrgSetting(enable_creds_breach, org)),
  }
}

const orgStoreInitialState = {
  organization: null,
}

export const useOrgStore = createWithEqualityFn<OrgStoreState>()(
  computed(
    (set) => ({
      ...orgStoreInitialState,

      // Setters
      setOrganization: (org: Organization) => set({ organization: org }),

      // Actions
      getOrgSetting: (settingKey, org) => {
        if (isObjEmpty(org) || !org) return

        return org.settings[settingKey]
      },

      reset: () => set(orgStoreInitialState),
    }),
    computeState,
  ),
  shallow,
)
