import { ROUTES } from '@enums/routes'
import { AuthService } from '@services/apis/auth'
import { QueryClient } from '@tanstack/react-query'
import { BaseComment } from '@typings/comment'
import { User } from '@typings/user'
import computed from 'zustand-computed'
import { shallow } from 'zustand/shallow'
import { createWithEqualityFn } from 'zustand/traditional'
import { useApiJobsStore } from './useApiJobsStore'
import { useOrgStore } from './useOrgStore'

type UserStoreState = {
  // State
  user: User
  isAuthenticated: boolean
  showEnableMFAModal: boolean

  // Setters
  setUser: (user: User) => void
  setIsAuthenticated: (isAuthenticated: boolean) => void
  setShowEnableMFAModal: (show: boolean) => void

  // Actions
  getIsAdminOrCommentOwner: (item: BaseComment) => boolean
  logoutUser: (replace: (path: string) => void, queryClient: QueryClient) => void
  reset: () => void
}

type ComputedStore = {
  isAdmin: boolean
  fullName: string
}

const computeState = (s: UserStoreState): ComputedStore => {
  return {
    isAdmin: s.user?.is_admin,
    fullName: `${s?.user?.first_name} ${s?.user?.last_name}`,
  }
}

const userStoreInitialState = {
  user: null,
  isAuthenticated: false,
  showEnableMFAModal: false,
}

export const useUserStore = createWithEqualityFn<UserStoreState>()(
  computed(
    (set, store) => ({
      ...userStoreInitialState,

      setUser: (user: User) => set({ user }),

      getIsAdminOrCommentOwner: (comment) => {
        if (!comment) return

        const isOwner = comment.fullname === store().fullName
        return store().isAdmin || isOwner
      },

      logoutUser: (replace, queryClient) => {
        replace(ROUTES.LOGIN) // First redirect to login page

        // Reset all the needed stores
        store().reset()
        useOrgStore.getState().reset()
        useApiJobsStore.getState().reset()

        queryClient.removeQueries() // So that all queries are re-executed on next login
        AuthService.logout() // Remove the session from the server at the end
      },

      setIsAuthenticated: (isAuthenticated) => set({ isAuthenticated }),

      setShowEnableMFAModal: (show) => set({ showEnableMFAModal: show }),

      reset: () => set(userStoreInitialState),
    }),

    computeState,
  ),
  shallow,
)
