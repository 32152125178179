import RoundCheckmark from '@assets/Common/round-checkmark.svg?react'
import ChevronDown from '@assets/Common/ChevronDown.svg?react'
import ExclamationIcon from '@assets/issues/open-issue.svg?react'
import DownloadIcon from '@assets/Common/Download.svg?react'
import FileIcon from '@assets/Common/file.svg?react'
import TrashIcon from '@assets/Common/Trash.svg?react'
import PlusIcon from '@assets/Common/plus.svg?react'
import PenIcon from '@assets/Common/pen.svg?react'
import CopyLinkIcon from '@assets/Common/copy-link.svg?react'
import CopyIcon from '@assets/Common/copy.svg?react'
import RestartIcon from '@assets/Common/restart.svg?react'
import { ButtonHTMLAttributes, FC, SVGProps, useMemo } from 'react'
import clsx from 'clsx'
import './ButtonElement.scss'

const PredefinedIcons = [
  'checkmark',
  'warning',
  'file',
  'download',
  'trash',
  'plus',
  'edit',
  'copyLink',
  'copy',
  'restart',
] as const

type IconProps = {
  icon: (typeof PredefinedIcons)[number] | FC<SVGProps<SVGSVGElement>>
  circular?: boolean
}

type ConditionalProps =
  | {
      children: React.ReactNode
      iconProps?: IconProps
    }
  | {
      children?: never
      iconProps: IconProps
    }

type ButtonElementProps = ConditionalProps & {
  variant?: 'primary' | 'danger' | 'select' | 'secondary'
  disabled?: boolean
  scaleOnTap?: boolean
  className?: string
  isActive?: boolean
  fullWidth?: boolean
  style?: React.CSSProperties
} & ButtonHTMLAttributes<HTMLButtonElement>

export const ButtonElement: FC<ButtonElementProps> = ({
  variant = 'primary',
  children,
  disabled,
  scaleOnTap,
  fullWidth,
  iconProps = {} as IconProps,
  style,
  isActive,
  className,
  ...btnProps
}) => {
  const Icon = useMemo(() => {
    if (!iconProps.icon) return null

    switch (iconProps.icon) {
      case 'checkmark':
        return RoundCheckmark
      case 'warning':
        return ExclamationIcon
      case 'file':
        return FileIcon
      case 'download':
        return DownloadIcon
      case 'trash':
        return TrashIcon
      case 'plus':
        return PlusIcon
      case 'edit':
        return PenIcon
      case 'copyLink':
        return CopyLinkIcon
      case 'copy':
        return CopyIcon
      case 'restart':
        return RestartIcon
      default:
        return iconProps.icon
    }
  }, [iconProps])

  return (
    <button
      className={clsx('button-element', {
        'active': isActive,
        'scale-on-tap': scaleOnTap && !disabled,
        'full-width': fullWidth,
        [variant]: variant,
        [className]: className,
      })}
      disabled={disabled}
      style={style}
      {...btnProps}
    >
      {Icon && <Icon className={clsx('button-element__icon', { circular: iconProps.circular })} />}

      {children}

      {variant === 'select' && <ChevronDown className='button-element__chevron' />}
    </button>
  )
}
